import React, { useEffect, useState } from "react";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PropTypes from "prop-types";

import HorariosContainer from "../containers/horarios"
import axios from "axios";
import configData from '../config.json'

const HorariosPage = ({ tournament }) => {
    const [tabActive, setTabActive] = useState(0)
    const tabs = [
        "Fase de grupos",
        "Fase final",
        "Clasificación",
    ]
    const handleTabChange = (key) => {
        setTabActive(key)
        localStorage.setItem("tabIndex", key)
    }
    const [categories, setCategories] = useState([])

    useEffect(() => {
        console.log(tournament)
    }, [tournament])

    const getCategories = async () => {
        const { data } = await axios.get(`${configData.apiServer}/categories/public/tournament/${tournament.id}`)
        const mascCat = data.filter((category) => category.parent.name.toLowerCase().includes('masc')).sort((a, b) => {
            if (a.parent.name.includes('+') || b.parent.name.includes('+')) {
                if (a.parent.name.includes(b.name) || b.parent.name.includes(a.parent.name)) {
                    return -1
                }
            }
            return (a.parent.name > b.parent.name) ? 1 : (b.parent.name > a.parent.name) ? -1 : 0
        })
        const femCat = data.filter((category) => category.parent.name.toLowerCase().includes('fem')).sort((a, b) => {
            if (a.parent.name.includes('+') || b.parent.name.includes('+')) {
                if (a.parent.name.includes(b.parent.name) || b.parent.name.includes(a.parent.name)) {
                    return -1
                }
            }
            return (a.parent.name > b.parent.name) ? 1 : (b.parent.name > a.parent.name) ? -1 : 0
        })
        const mixtCat = data.filter((category) => category.parent.name.toLowerCase().includes('mixt')).sort((a, b) => {
            if (a.parent.name.includes('+') || b.parent.name.includes('+')) {
                if (a.parent.name.includes(b.name) || b.parent.name.includes(a.parent.name)) {
                    return -1
                }
            }
            return (a.parent.name > b.parent.name) ? 1 : (b.parent.name > a.parent.name) ? -1 : 0
        })
        const categoriesList = femCat.concat(mascCat).concat(mixtCat)
        structureFinalMatches(categoriesList)
        //setCategories(categoriesList)
    }

    const structureFinalMatches = (categories) => {
        const newCategories = categories.map(category => {
            const fourth = {
                name: 'Cuartos',
                matches: category.matches.filter(match => match.type === 'Fourth')
            }
            const eighth = {
                name: 'Octavos',
                matches: category.matches.filter(match => match.type === 'Eighth')
            }
            const semifinal = {
                name: 'SemiFinales',
                matches: category.matches.filter(match => match.type === 'Semifinal')
            }
            const final = {
                name: 'Final',
                matches: category.matches.filter(match => match.type === 'Final')
            }
            return { ...category, finalMatches: [eighth, fourth, semifinal, final] }
        })
        console.log(newCategories)
        setCategories(newCategories)

    }


    useEffect(() => {
        setTabActive(Number(localStorage.getItem("tabIndex")) || 0)
        getCategories()
    }, [])
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Horarios" />
                <div className="wrapper">
                    <Header config={tournament} />
                    <div className="filtros-tabs">
                        {tabs.map((tab, key) => {
                            return (
                                <div aria-hidden="true" className={tabActive == key ? 'filtros-tab-active clickable' : 'filtros-tab clickable'} key={key} onClick={() => handleTabChange(key)}>
                                    {tab}
                                </div>
                            )
                        })}
                    </div>
                    <div className="" style={{ background: "rgba(#192D4D,.5)" }}>
                        <HorariosContainer tabIndex={tabActive} categories={categories}></HorariosContainer>
                    </div>
                    <Footer config={tournament} />
                    <ScrollToTop />
                </div>
            </Layout >
        </React.Fragment >
    );
};
HorariosPage.propTypes = {
    _id: PropTypes.string
};
export default HorariosPage;
