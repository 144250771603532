import "./registrationForm.scss"

import { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";

import axios from 'axios'
import moment, { locale } from "moment"
import 'moment/locale/es';

import formData from '../../data/torneo.json'
import configData from '../../config.json'

import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

const theme = createTheme({
    palette: {
        dualPadel: {
            main: '#92ED1B',
            contrastText: '#000',

        },
    },
});
const RegistrationForm = ({ lastTournament }) => {
    const politicaPrivacidadLabel1 = "Acepto ceder los derechos de imágenes o videos realizados durante el evento para su publicación posterior en la página web o redes sociales de DualPadel."
    const defaultData = {
        player1: {
            name: null,
            lastName: null,
            email: null,
            phone: null,
            size: 'L',
            terms: null,
            images: false
        },
        player2: {
            name: null,
            lastName: null,
            email: null,
            phone: null,
            size: 'L',
            terms: null,
            images: false
        },
        restrictions: [],
        categoryId: "",
        espera: false,
        tournamentId: lastTournament.id
    }

    const { register, errors, handleSubmit, clearErrors, setError } = useForm({
        mode: "onBlur",
    });
    const [termsAndConditions, setTermsAndConditions] = useState(false)
    const [privacyPolicy, setPrivacyPolicy] = useState(false)

    const [categoryValue, setCategoryValue] = useState(null)
    const [responseError, setResponseError] = useState(null)
    const [categories, setCategories] = useState([])
    const [tournament, setTournament] = useState(null)
    const [localeDate, setLocaleDate] = useState(null)
    const [data, setData] = useState(defaultData)
    const [dataloaded, setDataloaded] = useState(false)
    const history = useHistory()

    let isValidForm =
        Object.values(errors).filter((error) => {
            console.log(error)
            return (typeof error !== "undefined")
        }).length === 0;

    const emptyForm = Object.values(data.player1).some(x => x === null || x === '') || Object.values(data.player2).some(x => x === null || x === '') || data.categoryId === null || data.categoryId === ''

    const getTournament = async () => {
        console.log(lastTournament)
        const mascCat = lastTournament.categories.filter((category) => category.parent.name.toLowerCase().includes('masc')).sort((a, b) => {
            if (a.parent.name.includes('+') || b.parent.name.includes('+')) {
                if (a.parent.name.includes(b.parent.name) || b.parent.name.includes(a.parent.name)) {
                    return 1
                }
            }
            return (a.parent.name > b.parent.name) ? 1 : (b.parent.name > a.parent.name) ? -1 : 0
        })
        const femCat = lastTournament.categories.filter((category) => category.parent.name.toLowerCase().includes('fem')).sort((a, b) => {
            if (a.parent.name.includes('+') || b.parent.name.includes('+')) {
                if (a.parent.name.includes(b.name) || b.parent.name.includes(a.name)) {
                    return 1
                }
            }
            return (a.parent.name > b.parent.name) ? 1 : (b.parent.name > a.parent.name) ? -1 : 0
        })
        const mixtCat = lastTournament.categories.filter((category) => category.parent.name.toLowerCase().includes('mixt')).sort((a, b) => {
            if (a.parent.name.includes('+') || b.parent.name.includes('+')) {
                if (a.parent.name.includes(b.name) || b.parent.name.includes(a.name)) {
                    return 1
                }
            }
            return (a.parent.name > b.parent.name) ? 1 : (b.parent.name > a.parent.name) ? -1 : 0
        })
        const categoriesList = mascCat.concat(femCat).concat(mixtCat)
        setCategories(categoriesList)
        categoriesList.length > 0 && setCategoryValue(categoriesList[0]._id)
        setTournament(lastTournament)
        const dataCopy = data
        dataCopy.restrictions = lastTournament.restrictions
        setData(dataCopy)
        setLoading(false)
        categoriesList.length > 0 && handleChangeCategory({ target: { value: categoriesList[0]._id } })
        const dates = lastTournament.date.map(date => moment(date).locale("es"))
        setLocaleDate(dates)
        setDataloaded(true)
    }
    useEffect(() => {
        getTournament()
        console.log(lastTournament)
    }, [])

    const handleSend = () => {
        setLoading(true)
        axios.post(`${configData.apiServer}/teams`, data)
            .then((response) => {
                setLoading(false)
                history.push('/thank-you')
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
                if (error.response.data.error && error.response.data.error.includes(data.player1.email)) {
                    setResponseError(error.response.data.error)
                    setError('player1_name', { type: 'custom', message: error.response.data.error })
                }
                if (error.response.data.error && error.response.data.error.includes(data.player2.email)) {
                    setResponseError(error.response.data.error)
                    setError('player2_name', { type: 'custom', message: error.response.data.error })
                }
            })
    }
    const [fullCategory, setfullCategory] = useState(false)
    const [loading, setLoading] = useState(true)
    const isCategoryFull = (category) => {
        return category.teams.length >= category.teamsLimit
    }
    const handleChangeCategory = (event) => {
        setCategoryValue(event.target.value)
        if (lastTournament.openRegistrations) {
            axios.get(`${configData.apiServer}/categories/${event.target.value}`)
                .then((response) => {
                    setfullCategory(isCategoryFull(response.data))
                    setData({ ...data, categoryId: event.target.value, espera: isCategoryFull(response.data) })
                })
        }
    }
    const handleNamePlayer1 = (event) => {
        const splits = event.target.value.split(' ')
        const name = splits[0]
        splits.shift()
        const lastName = splits.join(' ')
        clearErrors('player1_name')
        console.log()
        setData({ ...data, player1: { ...data.player1, name: name, lastName: lastName } })
    }
    const handleEmailPlayer1 = (event) => {
        console.log(event.target.value.toLowerCase())
        if (data.player2.email === event.target.value.toLowerCase()) {
            console.log('Same email')
        }
        setData({ ...data, player1: { ...data.player1, email: event.target.value.toLowerCase() } })
    }
    const handlePhonePlayer1 = (event) => {
        setData({ ...data, player1: { ...data.player1, phone: event.target.value } })
    }
    const handleNamePlayer2 = (event) => {
        const splits = event.target.value.split(' ')
        const name = splits[0]
        splits.shift()
        const lastName = splits.join(' ')
        clearErrors('player2_name')
        console.log(errors)
        setData({ ...data, player2: { ...data.player2, name: name, lastName: lastName } })
    }
    const handleEmailPlayer2 = (event) => {
        console.log(event.target.value.toLowerCase())
        if (data.player1.email === event.target.value.toLowerCase()) {
            console.log('Same email')
        }
        setData({ ...data, player2: { ...data.player2, email: event.target.value.toLowerCase() } })
    }
    const handlePhonePlayer2 = (event) => {
        setData({ ...data, player2: { ...data.player2, phone: event.target.value } })
    }
    const getRestriction = (time) => {
        const timeRestriction = tournament.restrictions.filter(restriction => restriction.startTime == time)
        return timeRestriction.length > 0 ? timeRestriction[0] : time
    }
    const handleRestrictions = (event) => {
        const dataCopy = data
        if (event.target.checked) {
            dataCopy.restrictions.push(getRestriction(event.target.value))
        } else {
            dataCopy.restrictions = dataCopy.restrictions.filter(restriction => restriction.startTime !== event.target.value)
        }
        console.log(dataCopy)
        setData({ ...dataCopy })
    }

    const handlePoliticaPrivacidad = (event) => {
        if (event.target.checked) {
            setData({ ...data, player2: { ...data.player2, terms: true }, player1: { ...data.player1, terms: true } })
        } else {
            setData({ ...data, player2: { ...data.player2, terms: null }, player1: { ...data.player1, terms: null } })
        }
    }
    const handleImagePermissions = (event) => {
        if (event.target.checked) {
            setData({ ...data, player2: { ...data.player2, images: true }, player1: { ...data.player1, images: true } })
        } else {
            setData({ ...data, player2: { ...data.player2, images: false }, player1: { ...data.player1, images: false } })
        }
    }
    const handleTallaPlayer1 = (event) => {
        setData({ ...data, player1: { ...data.player1, size: event.target.value } })
    }
    const handleTallaPlayer2 = (event) => {
        setData({ ...data, player2: { ...data.player2, size: event.target.value } })
    }
    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                {loading &&
                    <div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                }
                {
                    dataloaded &&
                    <>
                        <FormControl >
                            <div className="formContainer">
                                <div className="topContainer">
                                    <div className="column f5">
                                        <FormLabel id="title">
                                            <h3>{tournament.name}</h3>
                                        </FormLabel>
                                        {
                                            localeDate &&
                                            <FormLabel id="date">
                                                {
                                                    localeDate.length == 1 ?

                                                        <>
                                                            Fecha del torneo: <strong>{localeDate[0].format("dddd")} {localeDate[0].format("DD")} de {localeDate[0].format("MMMM")} del {localeDate[0].format("YYYY")} </strong> de {tournament.schedule}
                                                        </> :
                                                        <>
                                                            Fecha del torneo: <strong>del {localeDate[0].format("dddd")} {localeDate[0].format("DD")} al {localeDate[localeDate.length - 1].format("dddd")} {localeDate[localeDate.length - 1].format("DD")} de {localeDate[0].format("MMMM")} del {localeDate[0].format("YYYY")} </strong>
                                                        </>
                                                }
                                            </FormLabel>
                                        }
                                        <FormLabel id="direction">
                                            Dirección del evento: {tournament.address}
                                        </FormLabel>
                                        <FormLabel id="contact">
                                            Ponte en contacto con nosotros para cualquier duda al <b>{formData.telefono}</b> o escribe a {formData.email} o al instagram de {formData.instagram}
                                        </FormLabel>
                                    </div>
                                    <div className="column f1">
                                        <div className="imageContainer">
                                            <img src={`${tournament.image}`} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <FormLabel id="categories" className="mt-3">
                                    <h5>Categoria</h5>
                                </FormLabel>
                                {!lastTournament.openRegistrations ?
                                    <Alert severity="error">
                                        <AlertTitle>Inscripciones Cerradas</AlertTitle>
                                        Pronto publicaremos el próximo torneo
                                    </Alert> : fullCategory ?
                                        <Alert severity="warning">
                                            <AlertTitle>Categoria llena</AlertTitle>
                                            Te añadimos a la lista de espera! <strong>¡No esperes más!</strong>
                                        </Alert> : <></>
                                }
                                <div className="rowContainer">


                                    <RadioGroup
                                        row
                                        required
                                        aria-labelledby="categories"
                                        name="radio-buttons-group"
                                        value={categoryValue}
                                        onChange={handleChangeCategory}
                                    >
                                        {
                                            categories && categories.map(
                                                (category, key) => {
                                                    return (
                                                        <FormControlLabel key={key} value={category._id} control={<Radio />} label={category.parent.name} />
                                                    )
                                                }
                                            )
                                        }
                                    </RadioGroup>
                                </div>

                                <FormLabel id="player1" className="mt-3">
                                    <h5>Jugador/a 1</h5>
                                </FormLabel>
                                <div className="col-12">
                                    <div className="rowContainer">
                                        <TextField
                                            onChange={handleNamePlayer1}
                                            className="m-2 col-11 col-md-3 textBox"
                                            required
                                            error={errors.player1_name ? true : false}
                                            inputRef={
                                                register({
                                                    required: "Name is required",
                                                    validate: value => {
                                                        if (value.split(' ').length < 2) {
                                                            return 'Incluye nombre y Apellidos'
                                                        }
                                                    }
                                                })}
                                            helperText={errors.player1_name ? errors.player1_name.message : ""}
                                            name="player1_name"
                                            label="Nombre y Apellidos"
                                            variant="outlined"
                                        />
                                        <TextField
                                            onChange={handleEmailPlayer1}
                                            className="m-2 col-11 col-md-3 textBox"
                                            required
                                            error={errors.player1_email ? true : false}
                                            inputRef={
                                                register({
                                                    required: "Email es requerido",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Email incorrecto"
                                                    },
                                                    validate: value => value.toLowerCase() !== data.player2.email || "Mismo email que Jugador 2"
                                                })
                                            }
                                            helperText={errors.player1_email ? errors.player1_email.message : ""}
                                            name="player1_email"
                                            label="Email"
                                            variant="outlined"
                                        />
                                        <TextField
                                            onChange={handlePhonePlayer1}
                                            className="m-2 col-11 col-md-3 textBox"
                                            required
                                            error={errors.player1_phone ? true : false}
                                            inputRef={
                                                register({
                                                    required: "Phone is required",
                                                    maxLength: 9,
                                                    validate: value => value !== data.player2.phone || "Mismo numero que Jugador 2",
                                                })
                                            }
                                            helperText={errors.player1_phone ? errors.player1_phone.message : ""}
                                            name="player1_phone"
                                            label="Teléfono"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="rowContainerCenter">
                                        <FormLabel id="talla_player1" className="m-2">
                                            <div>Talla Camiseta</div>
                                            <div style={{fontSize:11}}>Modelo unisex</div>
                                        </FormLabel>
                                        <ToggleButtonGroup
                                            className="m-3"
                                            required
                                            value={data.player1.size}
                                            exclusive
                                            aria-label="text alignment"
                                            onChange={handleTallaPlayer1}
                                        >
                                            <ToggleButton value="S" aria-label="left aligned">
                                                S
                                            </ToggleButton>
                                            <ToggleButton value="M" aria-label="centered">
                                                M
                                            </ToggleButton>
                                            <ToggleButton value="L" aria-label="right aligned">
                                                L
                                            </ToggleButton>
                                            <ToggleButton value="XL" aria-label="justified" >
                                                XL
                                            </ToggleButton>
                                            <ToggleButton value="2XL" aria-label="justified" >
                                                2XL
                                            </ToggleButton>
                                            {/* <ToggleButton value="3XL" aria-label="justified" >
                                                3XL
                                            </ToggleButton> */}
                                        </ToggleButtonGroup>
                                    </div>

                                </div>
                                <FormLabel id="player2" className="mt-3">
                                    <h5>Jugador/a 2</h5>
                                </FormLabel>
                                <div className="col-12">
                                    <div className="rowContainer">
                                        <TextField
                                            onChange={handleNamePlayer2}
                                            className="m-2 col-11 col-md-3 textBox"
                                            required
                                            error={errors.player2_name ? true : false}
                                            inputRef={
                                                register({
                                                    required: "Name is required",
                                                    validate: value => {
                                                        if (value.split(' ').length < 2) {
                                                            return 'Incluye nombre y Apellidos'
                                                        }
                                                    }
                                                })
                                            }
                                            helperText={errors.player2_name ? errors.player2_name.message : ""}
                                            name="player2_name"
                                            label="Nombre y Apellidos"
                                            variant="outlined"
                                        />
                                        <TextField
                                            onChange={handleEmailPlayer2}
                                            className="m-2 col-11 col-md-3 textBox"
                                            required
                                            error={errors.player2_email ? true : false}
                                            inputRef={
                                                register({
                                                    required: "Email is required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Email incorrecto"
                                                    },
                                                    validate: value => value.toLowerCase() !== data.player1.email || "Mismo email que Jugador 1"
                                                })
                                            }
                                            helperText={errors.player2_email ? errors.player2_email.message : ""}
                                            name="player2_email"
                                            label="Email"
                                            variant="outlined"
                                        />
                                        <TextField
                                            onChange={handlePhonePlayer2}
                                            className="m-2 col-11 col-md-3 textBox"
                                            required
                                            error={errors.player2_phone ? true : false}
                                            inputRef={
                                                register({
                                                    required: "Phone is required",
                                                    maxLength: 9,
                                                    validate: value => value !== data.player1.phone || "Mismo numero que Jugador 1",
                                                })
                                            }
                                            helperText={errors.player2_phone ? errors.player2_phone.message : ""}
                                            name="player2_phone"
                                            label="Teléfono"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="rowContainerCenter">
                                        <FormLabel id="talla_player2" className="m-2">
                                        <div>Talla Camiseta</div>
                                        <div style={{fontSize:11}}>Modelo unisex</div>
                                        </FormLabel>
                                        <ToggleButtonGroup
                                            className="m-3"
                                            required
                                            exclusive
                                            value={data.player2.size}
                                            aria-label="text alignment"
                                            onChange={handleTallaPlayer2}
                                        >
                                            <ToggleButton value="S" aria-label="left aligned">
                                                S
                                            </ToggleButton>
                                            <ToggleButton value="M" aria-label="centered">
                                                M
                                            </ToggleButton>
                                            <ToggleButton value="L" aria-label="right aligned">
                                                L
                                            </ToggleButton>
                                            <ToggleButton value="XL" aria-label="justified" >
                                                XL
                                            </ToggleButton>
                                            <ToggleButton value="2XL" aria-label="justified" >
                                                2XL
                                            </ToggleButton>
                                            {/* <ToggleButton value="3XL" aria-label="justified" >
                                                3XL
                                            </ToggleButton> */}
                                        </ToggleButtonGroup>
                                    </div>

                                </div>
                                <FormLabel id="restricciones_horarias" className="m-2">
                                    <p style={{ marginBottom: 0 }}>Restricciones horarias</p>
                                    <div style={{ fontSize: 13, fontWeight: 'bold' }}>**Cuando puedes jugar?</div>
                                </FormLabel>
                                <div className="timetableContainer">
                                    <div className="timetableRow">
                                        <div className="timetableColumn">
                                            {
                                                tournament && tournament.restrictions.map((timetable, key) => {
                                                    const name = `${moment(timetable.startTime).format('dddd').charAt(0).toUpperCase()}${moment(timetable.startTime).format('dddd').slice(1)} ${moment(timetable.startTime).format('HH:mm')} h a ${moment(timetable.endTime).format('HH:mm')} h`
                                                    return (key < tournament.restrictions.length / 2 && <FormControlLabel key={key} control={<Checkbox name="gilad" defaultChecked={true} onChange={handleRestrictions} value={timetable.startTime} disabled={timetable.blocked} />} label={name} />)
                                                })
                                            }
                                        </div>
                                        <div className="timetableColumn">
                                            {
                                                tournament && tournament.restrictions.map((timetable, key) => {
                                                    const name = `${moment(timetable.startTime).format('dddd').charAt(0).toUpperCase()}${moment(timetable.startTime).format('dddd').slice(1)} ${moment(timetable.startTime).format('HH:mm')} h a ${moment(timetable.endTime).format('HH:mm')} h`
                                                    return (key >= tournament.restrictions.length / 2 &&
                                                        <>
                                                            <FormControlLabel key={key} control={<Checkbox name="gilad" defaultChecked={true} onChange={handleRestrictions} value={timetable.startTime} disabled={timetable.blocked} />} label={name} />
                                                            {
                                                                timetable.blocked && <div className="blockedTimetable">**Dia bloqueado por finales</div>
                                                            }
                                                        </>
                                                    )

                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <FormControlLabel id="privacy_policy" control={
                                    <Checkbox name="gilad"
                                        defaultChecked={false}
                                        onChange={handleImagePermissions}
                                    />
                                }
                                    label={politicaPrivacidadLabel1}
                                />
                                <FormControlLabel id="terms_and_conditions" control={<Checkbox name="gilad" defaultChecked={false} onChange={handlePoliticaPrivacidad} required />} label={
                                    <div>
                                        Aceptamos los términos y condiciones de la <Link to={"/politica-de-privacidad"}>politica de privacidad</Link> de DualPadel.
                                    </div>
                                } />
                                <div>
                                    <Button disabled={!isValidForm || emptyForm || !lastTournament.openRegistrations} className="col-4 col-sm-3 mt-5 float-end" onClick={handleSend} size="large" variant="contained" color="dualPadel" style={{ fontFamily: '"Archivo", sans-serif' }}>
                                        Enviar
                                    </Button>
                                </div>
                            </div>

                        </FormControl>
                    </>}


            </Fragment>
        </ThemeProvider >
    );
};

RegistrationForm.propTypes = {
    config: PropTypes.any
};
export default RegistrationForm;
